<h3 class="t-title" *ngIf="heading">
  <span *polymorpheusOutlet="heading | prizmDocExampleCapitalize as text" [textContent]="text"></span>
  <button
    class="t-link-icon"
    *ngIf="id"
    [title]="copy$ | async"
    (click)="copyExampleLink()"
    tuiIconButton
    type="button"
    size="xs"
    icon="tuiIconLinkLarge"
    appearance="icon"
  ></button>

  <ng-container *ngIf="hasStackblitz">
    <prizm-dropdown-host [content]="dropdown" [isOpen]="isOpen" prizmDropdownHostWidth="auto">
      <button
        [icon]="icon"
        (click)="isOpen = !isOpen"
        prizmIconButton
        appearanceType="ghost"
        appearance="secondary"
        size="m"
      ></button>

      <ng-template #icon>
        <img class="icon-stackblitz" src="assets/logos/sb.png" />
      </ng-template>
    </prizm-dropdown-host>

    <ng-template #dropdown>
      <prizm-data-list [prizmDropdownMinHeight]="20">
        <div class="box">
          <button
            *ngIf="stackblitzVersions?.includes('1')"
            (click)="open('1')"
            prizmButton
            appearanceType="outline"
            size="s"
          >
            V1 (NG14)
          </button>

          <button
            *ngIf="stackblitzVersions?.includes('2')"
            (click)="open('2')"
            prizmButton
            appearanceType="outline"
            size="s"
          >
            V2 (NG15)
          </button>
          <button
            *ngIf="stackblitzVersions?.includes('3')"
            (click)="open('3')"
            prizmButton
            appearanceType="outline"
            size="s"
          >
            V3 (NG16)
          </button>
          <button
            *ngIf="stackblitzVersions?.includes('4')"
            (click)="open('4')"
            prizmButton
            appearanceType="outline"
            size="s"
          >
            V4 (NG17)
          </button>
        </div>
      </prizm-data-list>
    </ng-template>
  </ng-container>
</h3>

<h4 class="t-description" *ngIf="description">
  <ng-container *polymorpheusOutlet="description | prizmDocExampleCapitalize as text">
    {{ text }}
  </ng-container>
</h4>

<ng-container class="t-example" *ngIf="processor$ | async as files">
  <ng-container *ngIf="files | prizmDocExampleGetTabs: defaultTab as tabs">
    <div class="tabs" *ngIf="files | prizmDocExampleGetTabs: defaultTab as tabs">
      <prizm-switcher
        [(selectedSwitcherIdx)]="activeItemIndex"
        [switchers]="tabs | tuiMapper: tabsMap"
      ></prizm-switcher>
    </div>
    <div class="t-example">
      <div
        class="t-content"
        *ngFor="let tab of tabs; let index = index"
        [class.t-content_animated]="!isCypress"
        [class.t-content_visible]="activeItemIndex === index"
      >
        <ng-container
          *ngIf="index === defaultTabIndex; else anotherTab"
          [ngTemplateOutlet]="preview"
        ></ng-container>
        <ng-template #anotherTab>
          <ng-container
            [ngTemplateOutlet]="codeSection"
            [ngTemplateOutletContext]="{ $implicit: files[tabs[activeItemIndex]] || '' }"
          ></ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #preview>
  <div class="t-demo" automation-id="prizm-doc-example">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #codeSection let-code>
  <section class="t-code-actions">
    <ng-container *ngFor="let action of codeActions">
      <ng-container *polymorpheusOutlet="action as text; context: { $implicit: code }">
        {{ text }}
      </ng-container>
    </ng-container>
    <prizm-doc-copy [cdkCopyToClipboard]="code"></prizm-doc-copy>
  </section>
  <prizm-doc-code [code]="code"></prizm-doc-code>
</ng-template>
