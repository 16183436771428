<ng-container *prizmLet="chipsList$ | async as chipsList">
  <div
    class="chips-list"
    #overflowHost="prizmOverflowHost"
    *ngIf="!!chipsList?.length"
    [active]="singleLine"
    [class.hidden]="singleLine"
    [reserveSpace]="{ x: 48 }"
    [host]="layoutComponent?.el?.nativeElement"
    prizmOverflowHost
  >
    <prizm-chips-item
      #overflowItem="prizmOverflowItem"
      *ngFor="let item of chipsList; let i = index"
      [hintCanShow]="hintCanShow"
      [hintDirection]="hintDirection"
      [class.single-line]="singleLine"
      [hintText]="item | prizmCallFunc: stringify"
      [deletable]="deletable"
      [disabled]="accessorIsDisabled"
      [prizmContext]="item"
      [prizmContextKey]="overflowItem"
      (deleted)="removeChips($event, i, item)"
      (click)="chipClick(item)"
      prizmOverflowItem
    >
      {{ item | prizmCallFunc: stringify }}
    </prizm-chips-item>

    <ng-container *ngIf="overflowHost.active">
      <div
        class="more-item"
        *ngIf="(overflowHost.hiddenElements$ | async)?.length"
        [prizmHint]="overflowHost.hiddenElements$ | prizmGetContextByKeys | async | prizmCallFunc: joinHints"
        [prizmHintDirection]="hintDirection"
        [prizmHintCanShow]="hintCanShow"
      >
        ...
      </div>
    </ng-container>
  </div>
</ng-container>
