<div class="box prizm-font-main-body-14" prizmFocusTrap>
  <div class="content">
    <div class="title prizm-font-dynamic-title-h4" *ngIf="context.title">
      <ng-container *polymorphOutlet="context.title as data; context: context">
        {{ data }}
      </ng-container>
    </div>
    <div class="description prizm-font-main-body-14">
      <ng-container *polymorphOutlet="content() as data; context: context">
        {{ data }}
      </ng-container>

      <div class="footer prizm-font-btn-links-14">
        @let dictionary = dictionary$ | async;
        <ng-container>
          <div class="vertical">
            <div class="top">
              <ng-container
                *ngIf="context.confirmButton"
                [ngTemplateOutlet]="buttonTemplate"
                [ngTemplateOutletContext]="{
                  button: context.confirmButton,
                  defaultText: dictionary?.confirm
                }"
              >
              </ng-container>
              <ng-container
                *ngIf="context.supportButton"
                [ngTemplateOutlet]="buttonTemplate"
                [ngTemplateOutletContext]="
                  $any({ button: context.supportButton, defaultText: dictionary?.quit })
                "
              >
              </ng-container>
            </div>
            <div class="bottom">
              <ng-container
                *ngIf="context.cancelButton"
                [ngTemplateOutlet]="buttonTemplate"
                [ngTemplateOutletContext]="{ button: context.cancelButton, defaultText: dictionary?.cancel }"
              >
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #buttonTemplate let-button="button" let-defaultText="defaultText">
          <button
            [style]="button.style"
            [appearance]="button.appearance"
            [appearanceType]="button.appearanceType ?? 'fill'"
            [size]="button.size"
            [disabled]="$any(button.disabled ?? false | prizmToObservable | async)"
            [showLoader]="$any(button.showLoader ?? false | prizmToObservable | async)"
            (click)="button.action(context)"
            prizmButton
          >
            {{ button.text ?? defaultText }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
