<prizm-dropdown-host
  class="z-hosted"
  #prizmDropdownHostComponent
  [style.--prizm-dropdown-host-width]="'100%'"
  [canOpen]="!disabled"
  [prizmDropdownHost]="layoutComponent?.el?.nativeElement"
  [content]="$any(getTemplate(dropdownTimeTemplate, dropdownDateTemplate))"
  [prizmDropdownHostWidth]="openTimeTemplate ? '100%' : 'auto'"
  [isOpen]="!disabled && (openTimeTemplate || open)"
  [closeByEsc]="true"
  (isOpenChange)="onOpenChange($event); $event && prizmDropdownHostComponent.reCalculatePositions()"
>
  <div
    class="multiple-input-box"
    #focusableElementRef="prizmInputZone"
    [attr.data-placeholder]="placeholder"
    prizmInputZone
  >
    <input
      class="input-main"
      #fe="prizmInputInZone"
      #maskDirectiveDate="mask"
      [class.show-placeholder]="
        (empty | async) && (disabled || (focusableElementRef.focused$ | async) !== true)
      "
      [placeholder]="placeholder"
      [disabled]="disabled"
      [mask]="textMaskOptions"
      [showMaskTyped]="focusableElementRef.focused$ | async"
      [dropSpecialCharacters]="false"
      [size]="textMaskOptions.length"
      [clearIfNotMatch]="false"
      [maxLength]="textMaskOptions.length + 1"
      [maxSize]="textMaskOptions.length"
      [ngModel]="computedDateValue()"
      [ngModelOptions]="{ standalone: true }"
      (click)="referFocusToMain(!computedDateValue())"
      (ngModelChange)="onDateValueChange($event)"
      (updateNativeValue)="maskDirectiveDate.writeValue($event)"
      style="padding: 0"
      prizmInput
      prizmInputInZone
    />
    <input
      class="input-time"
      #element
      #maskDirectiveTime="mask"
      [mask]="timeMaskOptions"
      [clearIfNotMatch]="false"
      [dropSpecialCharacters]="false"
      [showMaskTyped]="focusableElementRef.focused$ | async"
      [disabled]="disabled"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="computedTimeValue()"
      [size]="timeMaskOptions.length"
      [maxLength]="timeMaskOptions.length + 1"
      [maxSize]="timeMaskOptions.length"
      (ngModelChange)="onTimeValueChange($event)"
      (updateNativeValue)="maskDirectiveTime.writeValue($event)"
      style="padding: 0"
      prizmInput
      prizmInputInZone
    />
    <!--    TODO add later correct fix for update native value when ng does not know about change -->
    <!--    [prizmInputNativeValue]='computedTimeValue()'-->
    <!--    [needChange]='needChangeNativeValue'-->
  </div>
</prizm-dropdown-host>
<ng-container *prizmInputLayoutRight>
  <button
    [interactive]="true"
    [disabled]="disabled"
    (click)="openDateDropdown(!open)"
    prizmInputIconButton="calendar-blank"
  ></button>
  <button
    [disabled]="disabled"
    [interactive]="true"
    (click)="openTimeDropdown(!openTimeTemplate)"
    prizmInputIconButton="clock"
  ></button>
  <ng-container *ngFor="let button of rightButtons$ | async" [ngTemplateOutlet]="button.template">
  </ng-container>
</ng-container>
<ng-template #dropdownDateTemplate>
  <prizm-calendar
    [min]="calendarMinDay"
    [max]="calendarMaxDay"
    [disabledItemHandler]="disabledItemHandler"
    [month]="computedActiveYearMonth"
    [value]="calendarValue"
    (prizmAfterViewInit)="markAsTouched()"
    (dayClick)="onDayClick($event)"
    (monthChange)="onMonthChange($event)"
    prizmPreventDefault="mousedown"
    automation-id="prizm-input-date-time__calendar"
  ></prizm-calendar>
</ng-template>

<ng-template #dropdownTimeTemplate>
  <prizm-data-list
    class="block"
    *ngIf="timeItems.length"
    [style.--prizm-data-list-border]="0"
    (prizmAfterViewInit)="markAsTouched(); prizmDropdownHostComponent.reCalculatePositions()"
    (prizmOnDestroy)="openTimeTemplate = false"
    prizmLifecycle
  >
    <ng-container>
      <prizm-listing-item
        *ngFor="let item of timeItems | prizmTimeConstraints: calendarValue : _min : _max; let idx = index"
        [selected]="value?.[1] && item.isSameTime($any(value?.[1]))"
        (click)="$event.stopPropagation(); onTimeMenuClick(item, $event)"
      >
        {{ item }}
      </prizm-listing-item>
    </ng-container>
  </prizm-data-list>
</ng-template>

<ng-template [enable]="!!ngControl.errors?.requiredPattern" prizmInputStatusText status="danger">
  {{ dictionary$ | async | prizmPluck: 'wrongFormat' }}
</ng-template>
