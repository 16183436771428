<prizm-dropdown-host
  #dropdownHostRef
  [content]="dropdown"
  [isOpen]="$any(opened$ | async)"
  [prizmDropdownHost]="layoutComponent?.el?.nativeElement"
  [prizmDropdownHostWidth]="dropdownWidth"
  [prizmDropdownMinHeight]="minDropdownHeight"
  [prizmDropdownMaxHeight]="maxDropdownHeight"
  [autoReposition]="!!dropdownAutoReposition"
  [dropdownStyles]="dropdownStyles"
  [dropdownClasses]="dropdownClasses"
  [ngSwitch]="layoutComponent?.outer"
  [dropdownTriggerElement]="layoutComponent?.el?.nativeElement"
  [dropdownDisabled]="disabled"
  [prizmFocusable]="true"
  (isOpenChange)="changeParentFocusedClass($event); $event && searchInputControl.setValue('')"
  dropdownTrigger="click"
>
  <input
    #focusableElementRef
    [style.display]="layoutComponent?.outer && value?.length ? 'none' : ''"
    [style.visibility]="!layoutComponent?.outer && value?.length ? 'hidden' : ''"
    [disabled]="disabled"
    [readonly]="true"
    [tabIndex]="!disabled ? 1 : -1"
    [placeholder]="placeholder"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="($any(value) | prizmCallFunc: stringify) ?? ''"
    (blur)="onTouch()"
    prizmInput
  />

  <ng-template #dropdown>
    <div class="list-search-item" *ngIf="searchable">
      <prizm-input-layout [label]="searchLabelTranslation$ | async" size="m">
        <input
          class="input-search"
          #input
          [formControl]="searchInputControl"
          (input)="dropdownHostRef.reCalculatePositions(1000 / 16)"
          prizmInput
          prizmAutoFocus
        />
        <button [interactive]="true" prizmInputIconButton="magnifying-glass" prizm-input-right></button>
      </prizm-input-layout>
    </div>
    <prizm-data-list
      class="block"
      *prizmLet="{ filteredItems: filteredItems$ | async } as $"
      [scroll]="dropdownScroll"
      [style.--prizm-data-list-border]="0"
      (prizmOnDestroy)="onTouch()"
    >
      <ng-container *ngIf="$.filteredItems?.length; else emptyTemplate">
        <div
          class="item"
          #parent
          *ngFor="let item of $.filteredItems; let idx = index; trackBy: trackBy"
          [class.selected]="item.checked"
          [class.most-relevant]="searchable && searchInputControl.value && idx === 0"
        >
          <prizm-checkbox
            [checked]="item.checked"
            [ngModel]="item.checked"
            [ngModelOptions]="{ standalone: true }"
            [host]="parent"
            [indeterminate]="!!item.indeterminate"
            (changed)="select(item)"
          >
            <div
              class="text"
              #elem
              *prizmLet="item | prizmCallFunc: stringify : $any(emptyContent) as text"
              [prizmHintOnOverflow]="text"
              [prizmHintDirection]="direction"
            >
              <ng-container
                *polymorphOutlet="
                  valueTemplate as content;
                  context: {
                    $implicit: {
                      obj: item.obj,
                      checked: item.checked,
                      stringify: item | prizmCallFunc: stringify,
                    },
                  }
                "
              >
                {{ text }}
              </ng-container>
            </div>
          </prizm-checkbox>
        </div>
      </ng-container>
      <ng-template #emptyTemplate>
        <ng-container *polymorphOutlet="emptyContent as data">
          <div class="empty-template">{{ emptyContent }}</div>
        </ng-container>
      </ng-template>
    </prizm-data-list>
  </ng-template>
</prizm-dropdown-host>

<ng-container *prizmInputLayoutRight>
  <ng-container
    *polymorphOutlet="
      icon || defaultIcon as iconName;
      context: $any({ opened: opened$ | async, disabled: disabled })
    "
  >
    <prizm-icons-full
      [class.opened]="opened$ | async"
      [class.active]="(focused$ | async) || (opened$ | async)"
      [class.icon-dropdown]="iconName === defaultIcon"
      [name]="$any(iconName)"
      [class.disabled]="disabled"
      [prizmFocusable]="!disabled"
    >
    </prizm-icons-full>
  </ng-container>
</ng-container>

<ng-container *prizmInputLayoutInBody>
  <div class="in-body-chips-box" *ngIf="value?.length">
    <ng-container [ngTemplateOutlet]="chipsTemplate"> </ng-container>
  </div>
</ng-container>

<ng-template #chipsTemplate>
  <ng-container *prizmLet="selectedItemsChips$ | async as chips">
    <prizm-chips
      [style.max-width.px]="
        layoutComponent?.el?.nativeElement
          ? layoutComponent!.el!.nativeElement.clientWidth - button_layout_width
          : 0
      "
      [class.empty]="!chips.length"
      [size]="$any(size)"
      [class.inner]="inner"
      [singleLine]="true"
      [deletable]="!disabled && isChipsDeletable"
      [chips]="chips"
      (removeChipEvent)="removeChip($event)"
    ></prizm-chips>
  </ng-container>
</ng-template>
