<ng-container *prizmLet="fileUpload$ | async as translations">
  <input
    class="hidden"
    #inputFile
    [multiple]="multiple"
    [accept]="accept"
    (change)="onFileInputChange($event)"
    type="file"
  />

  <div class="dropzone" #dropzone (drop)="onDrop($event)">
    <div class="dropzone__title">
      {{ translations | prizmPluck: ['dropzone__title'] }}
    </div>
    <div class="dropzone__description">
      {{ translations | prizmPluck: ['dropzone__description'] }}
    </div>
    <div class="dropzone__user-content">
      <ng-content></ng-content>
    </div>

    <button
      class="dropzone__file-select"
      [disabled]="disabled || files.length >= calculatedMaxFilesCount"
      [prizmHint]="translations | prizmPluck: ['btn__select_hint_error']"
      [prizmHintCanShow]="files.length >= calculatedMaxFilesCount"
      (click)="inputFile.click()"
      type="button"
      prizmButton
      appearanceType="outline"
      appearance="secondary"
      size="m"
    >
      {{ translations | prizmPluck: ['btn__select'] }}
    </button>
  </div>

  <div class="files" *ngIf="filesMap.size > 0">
    <div class="file files__item" *ngFor="let item of filesMap | keyvalue; trackBy: filesTrackBy">
      <div class="file__preview">
        <img
          class="file__image"
          *ngIf="item.value.url; else iconTpl"
          [src]="item.value.url | prizmSanitizer: 'bypassSecurityTrustResourceUrl'"
        />

        <ng-template #iconTpl>
          <prizm-icons-full class="file__icon" [size]="16" [name]="icon.name"></prizm-icons-full>
        </ng-template>
      </div>

      <div class="file__info">
        <div
          class="file__full-name"
          [prizmHint]="item.key"
          [prizmHintCanShow]="prizmIsTextOverflow(fileName)"
        >
          <span class="file__name" #fileName>{{ item.key | prizmFileName }}</span>
          <span class="file__extension">{{ item.key | prizmFileExtension }}</span>
        </div>
        <span class="file__size" *prizmLet="item.value.file.size | prizmFileSize as fileSize">
          {{ fileSize.value + ' ' }} {{ translations | prizmPluck: [fileSize.key] }}
        </span>
      </div>

      <div class="file__stage">
        {{ translations | prizmPluck: [item.value.progress | prizmUploadStatus: item.value.error] }}
      </div>

      <div class="file__actions">
        @for (action of actions(item.key); track action.event) {
          <button
            [disabled]="!!action.disabled || disabled"
            [icon]="action.icon"
            (click)="actionHandler(action.event, item.key)"
            prizmIconButton
            appearanceType="ghost"
            appearance="secondary"
            size="s"
          ></button>
        }
        <button
          class="file__retry-btn"
          *ngIf="item.value.error && options.showRetryButtons"
          [disabled]="disabled"
          [icon]="'arrow-rotate-right'"
          (click)="retryUpload(item.key)"
          prizmIconButton
          appearanceType="ghost"
          appearance="secondary"
          size="s"
        ></button>

        <button
          class="file__delete-btn"
          [disabled]="disabled"
          [icon]="'trash-empty'"
          (click)="removeFile(item.key)"
          prizmIconButton
          appearanceType="ghost"
          appearance="secondary"
          size="s"
        ></button>
      </div>

      <div class="file__progress progress">
        <progress
          class="progress__bar {{ item.value.progress | prizmUploadStatus: item.value.error }}"
          [value]="item.value.progress"
          prizmProgressBar
          max="100"
          size="s"
        ></progress>
        <span class="progress__value">{{ item.value.progress }}%</span>
      </div>
    </div>
  </div>
</ng-container>
