<prizm-scrollbar class="scroll-box">
  <ng-container *ngIf="columns.length; else dropZone">
    <prizm-listing-item
      *ngFor="let column of columns"
      cdkDrag
      cdkDragPreviewClass="prizm-high-index"
      prizmTheme
    >
      <ng-container leftBox>
        <!--        <prizm-icon [size]="24" iconClass="editor-dots"></prizm-icon>-->
        <prizm-icons [size]="24" name="grip-dots-vertical"></prizm-icons>
      </ng-container>
      <span>{{ column.name }}</span>
      <ng-container rightBox>
        <button
          [icon]="column.status | prizmColumnIcon"
          [disabled]="column.status === 'sticky' || (isLastColumnShown && column.status === 'default')"
          [prizmHint]="
            isLastColumnShown && column.status === 'default'
              ? (translations | prizmPluck: ['disabledHint'])
              : ''
          "
          (click)="toggleColumnStatus(column)"
          prizmIconButton
          appearanceType="ghost"
          appearance="secondary"
          size="m"
        ></button>
      </ng-container>
      <ng-container *cdkDragPlaceholder>
        <ng-container *ngTemplateOutlet="placeholder"></ng-container>
      </ng-container>
    </prizm-listing-item>
  </ng-container>
</prizm-scrollbar>

<ng-template #dropZone>
  <div class="dropzone">{{ translations | prizmPluck: ['dropzone'] }}</div>
</ng-template>

<ng-template #placeholder>
  <div class="dropzone"></div>
</ng-template>
