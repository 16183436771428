@let itemList = items();
<ng-container>
  @let val = items() && value | prizmCallFunc: getFullObjectOfCurrent : itemList;
  <prizm-dropdown-host
    #dropdownHostRef
    [content]="dropdown"
    [isOpen]="opened()"
    [prizmDropdownHost]="layoutComponent?.el?.nativeElement"
    [prizmDropdownHostWidth]="dropdownWidth"
    [prizmDropdownMinHeight]="minDropdownHeight"
    [prizmDropdownMaxHeight]="maxDropdownHeight"
    [autoReposition]="!!dropdownAutoReposition"
    [dropdownStyles]="dropdownStyles"
    [dropdownDisabled]="dropdownDisabled()"
    [dropdownTriggerElement]="layoutComponent?.el?.nativeElement"
    [dropdownClasses]="dropdownClasses"
    (isOpenChange)="onChangeModalVisible($event)"
    dropdownTrigger="click"
  >
    @let focused_ = (focused$$ | async) || false;
    @let text =
      val
        | prizmCallFunc: stringifyForInner : layoutComponent?.outer : layoutComponent?.label : placeholder
        | async;
    @if (valueTemplate) {
      @if (!focused_) {
        <ng-container
          *polymorphOutlet="
            editableTemplate as content;
            context: {
              $implicit: $any(val),
              value: val | prizmCallFunc: transformer,
              stringify: $any(val | prizmCallFunc: stringify | prizmToObservable | async),
            }
          "
        >
        </ng-container>
      } @else {
        <ng-container [ngTemplateOutlet]="inputTemplate"></ng-container>
      }
    } @else {
      <ng-container [ngTemplateOutlet]="inputTemplate"></ng-container>
    }

    <ng-template #inputTemplate>
      @let inputValue = search() || (userText ?? text ?? '');

      <input
        class="input-search"
        #focusableElementRef
        [placeholder]="placeholder"
        [disabled]="disabled"
        [prizmLifecycleDisabled]="!valueTemplate"
        [autoFocus]="focused_"
        [tabIndex]="!disabled ? 0 : -1"
        [prizmHintDirection]="prizmHintDirection"
        [prizmHintCanShow]="prizmHintCanShow"
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="inputValue"
        (prizmOnInit)="changeParentFocusedClass(true)"
        (ngModelChange)="searchEmit($event)"
        (focus)="focused$$.next(true)"
        (keypress)="printing.set($event)"
        (blur)="onTouch(); focused$$.next(false)"
        prizmAutoFocus
        prizmInput
      />
    </ng-template>

    <ng-template #editableTemplate>
      @let val = value | prizmCallFunc: getFullObjectOfCurrent : itemList;
      <div
        #focusableElementRef
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="(value | prizmCallFunc: getCurrentValue : itemList) ?? ''"
        [attr.contenteditable]="false"
        [tabIndex]="!disabled ? 1 : -1"
        [disabled]="disabled"
        (focus)="focused$$.next(true)"
        (blur)="focused$$.next(false)"
        prizmInput
        class="editable-wrapper"
      >
        <ng-container *ngIf="isNotNullish(val); else placeholderTemplate">
          <ng-container
            *polymorphOutlet="
              valueTemplate as content;
              context: {
                $implicit: $any(val),
                value: val | prizmCallFunc: transformer,
                stringify: $any(val | prizmCallFunc: stringify | prizmToObservable | async),
              }
            "
          >
            @let text = val | prizmCallFunc: stringify | prizmToObservable | async;
            <ng-container>
              {{ text }}
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #placeholderTemplate>
          <div class="placeholder">{{ userText || placeholder }}</div>
        </ng-template>
      </div>
    </ng-template>

    <ng-template #dropdown>
      @let items = (liveItems$ | async) ?? [];
      <prizm-data-list
        class="block"
        [scroll]="customItemDataList ? 'none' : dropdownScroll"
        [style.--prizm-data-list-border]="0"
        (prizmOnDestroy)="onTouch()"
      >
        <ng-container *ngIf="items?.length; else emptyTemplate">
          <ng-container *polymorphOutlet="customItemDataList?.template">
            <prizm-combobox-item *ngFor="let item of items" [item]="item"></prizm-combobox-item>
          </ng-container>
        </ng-container>
        <ng-template #emptyTemplate>
          <div class="empty-template">
            <ng-container *polymorphOutlet="emptyContent as content">
              {{ content }}
            </ng-container>
          </div>
        </ng-template>
      </prizm-data-list>
    </ng-template>
  </prizm-dropdown-host>

  <ng-container *prizmInputLayoutRight>
    <ng-container
      *polymorphOutlet="
        icon || defaultIcon as iconName;
        context: $any({ opened: opened(), disabled: disabled })
      "
    >
      <prizm-icons-full
        [class.disabled]="disabled"
        [class.opened]="opened()"
        [class.active]="(focused$ | async) || opened()"
        [class.icon-dropdown]="iconName === defaultIcon"
        [name]="$any(iconName)"
        [prizmFocusable]="true"
        (click)="(!disabled)"
      >
      </prizm-icons-full>
    </ng-container>
  </ng-container>
</ng-container>
