<div class="blocks">
  <ng-container *prizmLet="cronI18n$ | async as cronI18n">
    <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.every)">
      <label
        class="every value-block block"
        *prizmLet="cronUiState.TYPES.every as currentType"
        [class.selected]="cronUiState.typeControl.value === currentType"
      >
        <div class="radio">
          <prizm-radio-button
            [formControl]="cronUiState.typeControl"
            [label]="null"
            [value]="currentType"
            [name]="'type'"
            (changeEvent)="cronUiState.setEvery()"
          ></prizm-radio-button>
        </div>
        <div class="content row">
          {{ cronI18n | prizmPluck: ['everyYear'] }}
        </div>
      </label>
    </ng-container>

    <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.after)">
      <label
        class="block row"
        *prizmLet="cronUiState.TYPES.after as currentType"
        [class.selected]="cronUiState.typeControl.value === currentType"
      >
        <div class="radio">
          <prizm-radio-button
            [formControl]="cronUiState.typeControl"
            [label]="null"
            [value]="currentType"
            [name]="'type'"
            (changeEvent)="cronUiState.updateOn()"
          ></prizm-radio-button>
        </div>
        <div class="content row">
          <span>{{ cronI18n | prizmPluck: ['every'] }}</span>
          <prizm-cron-carousel
            [content]="cronUiState.list | prizmPluck: ['everyChosenTimesAfterChosen', 'on']"
            [value]="cronUiState.state$ | async | prizmPluck: ['everyChosenTimesAfterChosen', 'on']"
            (valueChange)="cronUiState.updateOn({ on: $event })"
          >
            <ng-template #content let-value="content">
              {{ value }}
            </ng-template>
          </prizm-cron-carousel>
          <span>{{ cronI18n | prizmPluck: ['yearSince'] }}</span>

          <prizm-cron-carousel
            [content]="cronUiState.list | prizmPluck: ['everyChosenTimesAfterChosen', 'after']"
            [value]="cronUiState.state$ | async | prizmPluck: ['everyChosenTimesAfterChosen', 'after']"
            (valueChange)="cronUiState.updateOn({ after: $event })"
          ></prizm-cron-carousel>
        </div>
      </label>
    </ng-container>

    <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.specified)">
      <label
        class="block"
        *prizmLet="cronUiState.TYPES.specified as currentType"
        [class.selected]="cronUiState.typeControl.value === currentType"
      >
        <div class="radio">
          <prizm-radio-button
            [formControl]="cronUiState.typeControl"
            [label]="null"
            [value]="currentType"
            [name]="'type'"
            (changeEvent)="scheduleComponent.emit()"
          ></prizm-radio-button>
        </div>
        <div class="content column year">
          <span>{{ cronI18n | prizmPluck: ['selectedYear'] }}</span>
          <div>
            <prizm-input-layout
              class="year-input"
              *prizmLet="
                cronUiState.state$ | async | prizmPluck: ['specified'] | prizmCallFunc: corrector as chips
              "
              [outer]="true"
              [label]="null"
              size="m"
            >
              <input
                #input
                [(ngModel)]="yearForAdd"
                [prizmInputAllowedSymbols]="allowedYear"
                [ngModelOptions]="{ standalone: true }"
                [placeholder]="cronI18n | prizmPluck: ['chooseYearLabel']"
                (enter)="onEnter($event, chipsComponent)"
                prizmInput
              />
              <prizm-chips
                #chipsComponent
                [ngModel]="chips"
                [deletable]="deletable"
                (ngModelChange)="removedChips($event)"
                prizm-input-bottom
              ></prizm-chips>
            </prizm-input-layout>

            <!--          <prizm-input-layout-->
            <!--            class="year-input"-->
            <!--            *prizmLet="-->
            <!--              cronUiState.state$ | async | prizmPluck : ['specified'] | prizmCallFunc : join as joinedStr-->
            <!--            "-->
            <!--            [outer]="true"-->
            <!--            [label]="null"-->
            <!--            [forceClear]="false"-->
            <!--            size="m"-->
            <!--          >-->
            <!--            <input-->
            <!--              [prizmInputAllowedSymbols]="allowedYear"-->
            <!--              [prizmInputCorrector]="corrector"-->
            <!--              [ngModel]="joinedStr"-->
            <!--              [ngModelOptions]="{ standalone: true }"-->
            <!--              (ngModelChange)="saveSpecified($event)"-->
            <!--              prizmInput-->
            <!--            />-->
            <!--          </prizm-input-layout>-->
          </div>
          <prizm-cron-schedule
            #scheduleComponent
            [items]="cronUiState.list.specified"
            [selected]="cronUiState.state$ | async | prizmPluck: ['specified']"
            (selectedChange)="cronUiState.updateSpecified($event)"
          ></prizm-cron-schedule>
        </div>
      </label>
    </ng-container>

    <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.between)">
      <label
        class="block row"
        *prizmLet="cronUiState.TYPES.between as currentType"
        [class.selected]="cronUiState.typeControl.value === currentType"
      >
        <div class="radio">
          <prizm-radio-button
            [formControl]="cronUiState.typeControl"
            [label]="null"
            [value]="currentType"
            [name]="'type'"
            (changeEvent)="cronUiState.updateBetween()"
          ></prizm-radio-button>
        </div>
        <div class="content">
          <span>
            {{ cronI18n | prizmPluck: ['everyYearFrom'] }}
          </span>
          <prizm-cron-carousel
            #start
            [content]="cronUiState.list.between.start"
            [value]="cronUiState.state$ | async | prizmPluck: ['between', 'start']"
            (valueChange)="cronUiState.updateBetween({ start: $event })"
          ></prizm-cron-carousel>
          <span>{{ cronI18n | prizmPluck: ['to'] }}</span>
          <prizm-cron-carousel
            #end
            [content]="cronUiState.list.between.end"
            [value]="cronUiState.state$ | async | prizmPluck: ['between', 'end']"
            (valueChange)="cronUiState.updateBetween({ end: $event })"
          ></prizm-cron-carousel>
        </div>
      </label>
    </ng-container>
  </ng-container>
</div>
